import { t } from "i18next";
import React, { useEffect } from "react";
import { paths } from "./utils/paths";
import { NavLink } from "react-router-dom";

function PageNotFound() {
  useEffect(() => {
    document.title = "Sorry, this page isn't available";
  });

  return (
    <section
      class="login d-flex justify-content-center"
      style={{ height: "100vh" }}
    >
      <div class="container">
        <div
          class="form-signin w-100 text-center"
          style={{ paddingBlock: "80px", marginTop: "80px" }}
        >
          <div className="display-1">
            <span style={{ color: "#334660", fontWeight: 600 }}>4</span>
            <span style={{ color: "#4c7ee8", fontWeight: 600 }}>0</span>
            <span style={{ color: "#334660", fontWeight: 600 }}>4</span>
          </div>
          <div
            style={{ fontSize: "23px", color: "#334660" }}
            className="fw-bold"
          >
            {t("Sorry, this page isn't available")}
          </div>
          <p>
            {t(
              "The link you followed may be broken, or the page may have been removed."
            )}
          </p>
          <div class="tab-content" id="myTabContent">
            <a
              className="fw-semibold fs-6"
              style={{ color: "#334660" }}
              href="/"
            >
              {t("Go to home page")}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PageNotFound;
