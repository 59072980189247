export const ldSchemas = {
  home: {
    "@context": "https://schema.org",
    "@type": "Corporation",
    name: "Ako Sayara",
    alternateName: "Ako Sayara",
    url: "https://akosayara.com/",
    logo: "https://akosayara.com/assets/images/Logo.webp",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "",
      contactType: "customer service",
      areaServed: "IQ",
      availableLanguage: ["en", "Arabic", "Kurdish"],
    },
    sameAs: [
      "https://www.facebook.com/akosayara",
      "https://twitter.com/akosayara",
      "https://www.instagram.com/akosayara/",
      "https://youtube.com/@akosayara?si=959yernRHGst8Y4Y",
      "https://www.linkedin.com/company/akosayara/",
      "",
    ],
  },
  rentCar: {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.akosayara.com/Rent-a-car",
    },
    headline:
      "Buy Sell & Rent Your Cars in Iraq & Bagdad - About Us Ako Sayara",
    description:
      "**Ako Sayara** is a leading online platform in Iraq for buying, selling, and renting cars. It offers a wide selection of vehicles, from economy to luxury models, catering to individual and business needs. With a user-friendly website and reliable customer service, Ako Sayara connects buyers and sellers, making car transactions seamless and trustworthy. Whether you're looking for a car to rent or purchase, Ako Sayara provides a convenient and efficient solution for all your automotive needs.",
    image:
      "https://akosayara.s3.eu-central-1.amazonaws.com/public/1708934138761b64fe1bf-1.png",
    author: {
      "@type": "Organization",
      name: "Ako Sayara",
      url: "https://akosayara.com",
    },
    publisher: {
      "@type": "Organization",
      name: "",
      logo: {
        "@type": "ImageObject",
        url: "",
      },
    },
    datePublished: "2024-09-09",
    dateModified: "2024-09-09",
  },
  checkYourCars: {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.akosayara.com/contact",
    },
    headline:
      "Buy Sell & Rent Your Cars in Iraq & Bagdad - Contact Us Ako Sayara",
    description:
      "If you have any questions or need assistance, the **Ako Sayara** team is here to help! Whether you're looking to buy, sell, or rent a car, our customer service is ready to provide support. Reach out to us via phone, email, or through our online contact form, and we'll get back to you promptly. Your satisfaction is our priority!",
    image: "",
    author: {
      "@type": "Organization",
      name: "Ako Sayara",
      url: "https://akosayara.com",
    },
    publisher: {
      "@type": "Organization",
      name: "",
      logo: {
        "@type": "ImageObject",
        url: "",
      },
    },
    datePublished: "2024-09-09",
    dateModified: "2024-09-09",
  },
  sellYourCar: {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.akosayara.com/Closeaccount",
    },
    headline:
      "Buy Sell & Rent Your Cars in Iraq & Bagdad - Ako Sayara Close Account",
    description:
      "If you wish to close your **Ako Sayara** account, we're here to assist you. Simply contact our support team via email or through our online contact form with your account details, and we'll guide you through the process. Please note that once your account is closed, all your saved data, listings, and preferences will be permanently deleted.",
    image: "",
    author: {
      "@type": "Organization",
      name: "Ako Sayara",
      url: "https://akosayara.com",
    },
    publisher: {
      "@type": "Organization",
      name: "",
      logo: {
        "@type": "ImageObject",
        url: "",
      },
    },
    datePublished: "2024-09-09",
    dateModified: "2024-09-09",
  },
  rentYourCar: {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.akosayara.com/careers",
    },
    headline:
      "Buy Sell & Rent Your Cars in Iraq & Bagdad - Career In Ako Sayara",
    description:
      "Join the dynamic team at **Ako Sayara** and build your career in the automotive industry! We are always looking for passionate individuals who are eager to contribute to our mission of revolutionizing the car buying, selling, and renting experience. Explore exciting opportunities in various departments, from customer service to tech development. At Ako Sayara, you can grow professionally while making a difference in the automotive world.",
    image: "",
    author: {
      "@type": "Organization",
      name: "Ako Sayara",
      url: "https://akosayara.com",
    },
    publisher: {
      "@type": "Organization",
      name: "",
      logo: {
        "@type": "ImageObject",
        url: "",
      },
    },
    datePublished: "2024-09-09",
    dateModified: "2024-09-09",
  },
  aboutSayara: {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.akosayara.com/aboutSayara",
    },
    headline:
      "Buy Sell & Rent Your Cars in Iraq & Bagdad - About Us Ako Sayara",
    description:
      "**Ako Sayara** is a leading online platform in Iraq for buying, selling, and renting cars. It offers a wide selection of vehicles, from economy to luxury models, catering to individual and business needs. With a user-friendly website and reliable customer service, Ako Sayara connects buyers and sellers, making car transactions seamless and trustworthy. Whether you're looking for a car to rent or purchase, Ako Sayara provides a convenient and efficient solution for all your automotive needs.",
    image:
      "https://akosayara.s3.eu-central-1.amazonaws.com/public/1708934138761b64fe1bf-1.png",
    author: {
      "@type": "Organization",
      name: "Ako Sayara",
      url: "https://akosayara.com",
    },
    publisher: {
      "@type": "Organization",
      name: "",
      logo: {
        "@type": "ImageObject",
        url: "",
      },
    },
    datePublished: "2024-09-09",
    dateModified: "2024-09-09",
  },
  contact: {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.akosayara.com/contact",
    },
    headline:
      "Buy Sell & Rent Your Cars in Iraq & Bagdad - Contact Us Ako Sayara",
    description:
      "If you have any questions or need assistance, the **Ako Sayara** team is here to help! Whether you're looking to buy, sell, or rent a car, our customer service is ready to provide support. Reach out to us via phone, email, or through our online contact form, and we'll get back to you promptly. Your satisfaction is our priority!",
    image: "",
    author: {
      "@type": "Organization",
      name: "Ako Sayara",
      url: "https://akosayara.com",
    },
    publisher: {
      "@type": "Organization",
      name: "",
      logo: {
        "@type": "ImageObject",
        url: "",
      },
    },
    datePublished: "2024-09-09",
    dateModified: "2024-09-09",
  },
  closeAccount: {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.akosayara.com/Closeaccount",
    },
    headline:
      "Buy Sell & Rent Your Cars in Iraq & Bagdad - Ako Sayara Close Account",
    description:
      "If you wish to close your **Ako Sayara** account, we're here to assist you. Simply contact our support team via email or through our online contact form with your account details, and we'll guide you through the process. Please note that once your account is closed, all your saved data, listings, and preferences will be permanently deleted.",
    image: "",
    author: {
      "@type": "Organization",
      name: "Ako Sayara",
      url: "https://akosayara.com",
    },
    publisher: {
      "@type": "Organization",
      name: "",
      logo: {
        "@type": "ImageObject",
        url: "",
      },
    },
    datePublished: "2024-09-09",
    dateModified: "2024-09-09",
  },
  careers: {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.akosayara.com/careers",
    },
    headline:
      "Buy Sell & Rent Your Cars in Iraq & Bagdad - Career In Ako Sayara",
    description:
      "Join the dynamic team at **Ako Sayara** and build your career in the automotive industry! We are always looking for passionate individuals who are eager to contribute to our mission of revolutionizing the car buying, selling, and renting experience. Explore exciting opportunities in various departments, from customer service to tech development. At Ako Sayara, you can grow professionally while making a difference in the automotive world.",
    image: "",
    author: {
      "@type": "Organization",
      name: "Ako Sayara",
      url: "https://akosayara.com",
    },
    publisher: {
      "@type": "Organization",
      name: "",
      logo: {
        "@type": "ImageObject",
        url: "",
      },
    },
    datePublished: "2024-09-09",
    dateModified: "2024-09-09",
  },
  policies: {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.akosayara.com/policies",
    },
    headline:
      "Buy Sell & Rent Your Cars in Iraq & Bagdad - Policy For Ako Sayara",
    description:
      "At **Ako Sayara**, your privacy is important to us. Our **Privacy Policy** outlines how we collect, use, and protect your personal information when you use our platform. We are committed to ensuring your data is secure and used responsibly, in compliance with industry standards. By using our services, you agree to the terms outlined in this policy, designed to safeguard your privacy and enhance your experience.",
    image: "",
    author: {
      "@type": "Organization",
      name: "Ako Sayara",
      url: "https://akosayara.com",
    },
    publisher: {
      "@type": "Organization",
      name: "",
      logo: {
        "@type": "ImageObject",
        url: "",
      },
    },
    datePublished: "2024-09-09",
    dateModified: "2024-09-09",
  },
  conditions: {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.akosayara.com/conditions",
    },
    headline: "Buy Sell & Rent Your Cars in Iraq & Bagdad - T&C For Ako Sayara",
    description:
      "The **Terms & Conditions** of **Ako Sayara** outline the rules and guidelines for using our platform. By accessing and using our services, you agree to these terms, which cover account usage, transactions, privacy, and other important aspects of our website. These terms ensure a safe, fair, and transparent experience for all users, whether you're buying, selling, or renting a car.",
    image: "",
    author: {
      "@type": "Organization",
      name: "Ako Sayara",
      url: "https://akosayara.com",
    },
    publisher: {
      "@type": "Organization",
      name: "",
      logo: {
        "@type": "ImageObject",
        url: "",
      },
    },
    datePublished: "2024-09-09",
    dateModified: "2024-09-09",
  },
  newCarDeals: {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.akosayara.com/New-car-deals",
    },
    headline: "New car deals in Iraq - Ako Sayara",
    description:
      "Discover the latest **New Car Deals** at **Ako Sayara**! We offer exclusive discounts and promotions on a wide range of brand-new vehicles, from economy to luxury models. Whether you're looking for a sleek sedan or a family SUV, our deals help you find the perfect car at unbeatable prices. Don't miss out—explore our new car deals today!",
    image: "",
    author: {
      "@type": "",
      name: "",
    },
    publisher: {
      "@type": "Organization",
      name: "Ako Sayara",
      logo: {
        "@type": "ImageObject",
        url: "https://akosayara.com/assets/images/Logo.webp",
      },
    },
    datePublished: "2024-09-20",
    dateModified: "2024-09-20",
  },
  classicCars: {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.akosayara.com/Classic-cars",
    },
    headline: "Classic cars in Iraq - Ako Sayara",
    description:
      "Explore a unique selection of **Classic Cars in Iraq** at **Ako Sayara**. Whether you're a collector or simply a fan of vintage automobiles, we offer a range of timeless classics that embody elegance and history. From restored models to rare finds, our collection provides a glimpse into automotive heritage. Discover and own a piece of history today!",
    image: "",
    author: {
      "@type": "",
      name: "",
    },
    publisher: {
      "@type": "Organization",
      name: "Ako Sayara",
      logo: {
        "@type": "ImageObject",
        url: "https://akosayara.com/assets/images/Logo.webp",
      },
    },
    datePublished: "2024-09-20",
    dateModified: "2024-09-20",
  },
  electricCars: {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.akosayara.com/Electric-cars",
    },
    headline: "Buy , Sell & Rent Electric cars in Iraq",
    description:
      "At **Ako Sayara**, you can easily **Buy, Sell, or Rent Electric Cars in Iraq**. As the demand for eco-friendly vehicles grows, we offer a wide range of electric cars that combine cutting-edge technology with sustainability. Whether you're looking to purchase, list your electric car for sale, or rent one for short-term use, our platform provides a seamless experience. Join the green revolution and explore electric car options today!",
    image: "",
    author: {
      "@type": "",
      name: "",
    },
    publisher: {
      "@type": "Organization",
      name: "Ako Sayara",
      logo: {
        "@type": "ImageObject",
        url: "https://akosayara.com/assets/images/Logo.webp",
      },
    },
    datePublished: "2024-09-20",
    dateModified: "2024-09-20",
  },
  rentPerfectCars: {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.akosayara.com/Rent-your-perfect-car",
    },
    headline: "Rent your perfect car in Iraq - Ako Sayara",
    description:
      "Looking to **Rent Your Perfect Car in Iraq**? **Ako Sayara** offers a wide variety of rental cars to suit every need, from economy to luxury models. Whether it's for a business trip, family outing, or special occasion, find the ideal vehicle with ease. With flexible options and reliable service, Ako Sayara makes car rentals simple and convenient. Rent your perfect car today!",
    image: "",
    author: {
      "@type": "",
      name: "",
    },
    publisher: {
      "@type": "Organization",
      name: "Ako Sayara",
      logo: {
        "@type": "ImageObject",
        url: "https://akosayara.com/assets/images/Logo.webp",
      },
    },
    datePublished: "2024-09-20",
    dateModified: "2024-09-20",
  },
  articles: {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.akosayara.com/Articles",
    },
    headline: "Our Articles & News - Ako Sayara",
    description:
      "Stay informed with **Our Articles & News** at **Ako Sayara**. Explore the latest updates on the automotive industry, car maintenance tips, market trends, and exclusive insights into buying, selling, and renting cars in Iraq and the UAE. Our expert-written articles keep you up-to-date and help you make informed decisions for all your automotive needs.",
    image: "",
    author: {
      "@type": "",
      name: "",
    },
    publisher: {
      "@type": "Organization",
      name: "Ako Sayara",
      logo: {
        "@type": "ImageObject",
        url: "https://akosayara.com/assets/images/Logo.webp",
      },
    },
    datePublished: "2024-09-20",
    dateModified: "2024-09-20",
  },
  advancedSearch: {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.akosayara.com/Advanced-search",
    },
    headline: "Advanced search - Ako Sayara",
    description:
      "Use the **Advanced Search** feature on **Ako Sayara** to find your ideal car quickly and easily. Filter results by make, model, price, year, location, and more to customize your search and discover the perfect vehicle for buying, selling, or renting. Our advanced tools help you navigate through a wide selection of cars to match your specific needs effortlessly.",
    image: "",
    author: {
      "@type": "",
      name: "",
    },
    publisher: {
      "@type": "Organization",
      name: "Ako Sayara",
      logo: {
        "@type": "ImageObject",
        url: "https://akosayara.com/assets/images/Logo.webp",
      },
    },
    datePublished: "2024-09-20",
    dateModified: "2024-09-20",
  },
  buyCar: {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.akosayara.com/Search-results/Buy-a-car",
    },
    headline: "Buy Cars In iraq, Purchase Cars In Iraq - Ako Sayara",
    description:
      "Looking to **Buy Cars in Iraq**? **Ako Sayara** offers a vast selection of vehicles to suit every budget and preference, from economy models to luxury cars. Our user-friendly platform makes it easy to browse listings, compare options, and find the perfect car. Whether you're searching for a brand-new vehicle or a reliable used car, Ako Sayara provides a seamless buying experience. Start your search today!",
    image: "",
    author: {
      "@type": "",
      name: "",
    },
    publisher: {
      "@type": "Organization",
      name: "Ako Sayara",
      logo: {
        "@type": "ImageObject",
        url: "https://akosayara.com/assets/images/Logo.webp",
      },
    },
    datePublished: "2024-09-20",
    dateModified: "2024-09-20",
  },
  whyAkoSayara: {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.akosayara.com/Why-ako-sayara",
    },
    headline: "Rental Cars In iraq - Ako Sayara",
    description:
      "Ako Sayara is Iraq's trusted digital automotive marketplace for buying, selling, renting, and checking cars. We combine data-driven insights with modern agility to provide an exceptional car shopping experience.",
    image: "",
    author: {
      "@type": "",
      name: "",
    },
    publisher: {
      "@type": "Organization",
      name: "Ako Sayara",
      logo: {
        "@type": "ImageObject",
        url: "https://akosayara.com/assets/images/Logo.webp",
      },
    },
    datePublished: "2024-09-20",
    dateModified: "2024-09-20",
  },
  singleCar: {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.akosayara.com/Single-car",
    },
    headline: "Single Car - Ako Sayara",
    description:
      "Discover all the details of your desired vehicle with Single Car listings on  Ako Sayara. Each car page provides comprehensive information, including features, specifications, price, and high-quality images, helping you make an informed decision. Whether you're looking to buy, sell, or rent, explore every aspect of the car in one convenient place.",
    image: "",
    author: {
      "@type": "",
      name: "",
    },
    publisher: {
      "@type": "Organization",
      name: "Ako Sayara",
      logo: {
        "@type": "ImageObject",
        url: "https://akosayara.com/assets/images/Logo.webp",
      },
    },
    datePublished: "2024-09-20",
    dateModified: "2024-09-20",
  },
  singleRental: {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.akosayara.com/Single-car",
    },
    headline: "Single Car - Ako Sayara",
    description:
      "Discover all the details of your desired vehicle with Single Car listings on  Ako Sayara. Each car page provides comprehensive information, including features, specifications, price, and high-quality images, helping you make an informed decision. Whether you're looking to buy, sell, or rent, explore every aspect of the car in one convenient place.",
    image: "",
    author: {
      "@type": "",
      name: "",
    },
    publisher: {
      "@type": "Organization",
      name: "Ako Sayara",
      logo: {
        "@type": "ImageObject",
        url: "https://akosayara.com/assets/images/Logo.webp",
      },
    },
    datePublished: "2024-09-20",
    dateModified: "2024-09-20",
  },

  cookies: {},
};
