import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlItem from "../../Tools/OwlItem";
import { homeOptions } from "../../../data/content";
import OwlItemCars from "../../Tools/OwlItem/owlItemCars";
import { paths } from "../../../utils/paths";
export default function OtherCar({ data, page, carData }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  let rtl = false;
  switch (i18n.language) {
    case "English":
      rtl = false;
      break;
    case "Arabic":
      rtl = true;
      break;
    case "Kurdish":
      rtl = true;
      break;
  }
  const goToViewAllCars = (id) => {
    if(page === 'normal') {
      navigate(`${paths.dealerCarView}?ownerId=${id}`);
    } else {
      navigate(`${paths.dealerRental}?ownerId=${id}`);
    }
  };
  
  const [radioLoading, setRadioLoading] = useState(false);
  const [rentType, setRentType] = useState(page == "normal" ? page : 'daily');

  const handleChnage = (e) => {
    setRadioLoading(true);
    setTimeout(() => {
      setRadioLoading(false);
    }, 500);
    setRentType(e.target.value);
  };

  return (
    <section class="recently_viewed Cars1">
      {/* {data && ( */}
      <div class="container">
        <h2 class="hed">{t("Other cars from this seller")}</h2>
        {page == "rental" && (
          <h6>
            <label htmlFor="othdaily" className="mx-2" style={{cursor: 'pointer'}}>
              <input
                type="radio"
                id="othdaily"
                checked={rentType === "daily"}
                onChange={handleChnage}
                value="daily"
              />{" "}
              {t("Daily")}
            </label>{" "}
            <label htmlFor="othweekly" className="mx-2" style={{cursor: 'pointer'}}>
              <input
                type="radio"
                id="othweekly"
                value={"weekly"}
                checked={rentType === "weekly"}
                onChange={handleChnage}
              />{" "}
              {t("Weekly")}
            </label>{" "}
            <label htmlFor="othmonthly" className="mx-2" style={{cursor: 'pointer'}}>
              <input
                type="radio"
                id="othmonthly"
                onChange={handleChnage}
                value={"monthly"}
                checked={rentType === "monthly"}
              />{" "}
              {t("Monthly")}
            </label>
          </h6>
        )}
        {!radioLoading ? (
          <OwlCarousel
            className={`owl-carousel Recently_carousel owl.carousel.min owl-theme ${rtl && 'invertButtons'}`}
            {...homeOptions}
            // margin={10}
            rtl={rtl}
            nav
          >
            <OwlItemCars cars={data} rentType={rentType} />

            {/* <OwlItem
              key={0}
              fill={"heart_fill.png"}
              unfill={"heart.webp"}
              responce={data}
              page={"BrandNewSection"}
            /> */}
          </OwlCarousel>
        ) : (
          <div
            style={{ height: "54vh" }}
            className="container d-flex justify-content-center align-items-center"
          >
            <div>
              <a disabled>
                <span
                  class="spinner-grow spinner-grow"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden" role="status">
                  Loading...
                </span>
              </a>
            </div>
          </div>
        )}
        <div class="w-100 float-left text-center">
          <a
            className={"viewall"}
            onClick={() => goToViewAllCars(carData?.owner?._id)}
          >
            {t("View all their cars")}
          </a>
        </div>
      </div>
      {/* // )} */}
    </section>
  );
}
