import React, { useEffect, useState } from 'react';
import Select from './select';
import Input from './input';
import Checkbox from './checkbox';
import SidebarDropDown from './sideBarDropDown';
import CFPrice from './cfPrice';
import DWM from './dwm';
import KilometerInput from './kmInput';
import { t } from 'i18next';
import { getModels } from '../../../Reducer/api';
import RadioSelect from "./radioSelect"

export default function FilterModel({ title, data, type, id, handleChange, handleClick, dataType, allFilters, setAllFilters, showFilteredValues, setSelected, page, models, setModels }) {


    const fetchList = () => {
        let val = '';
        switch (type) {
            case 'make':
                val = <Select id={id} type={type} data={data} title={title} allFilters={allFilters} setAllFilters={setAllFilters} setSelected={setSelected} />;
                break;
            case 'model':
                val = <Select id={id} type={type} data={models} title={title} allFilters={allFilters} setAllFilters={setAllFilters} setSelected={setSelected} />;
                break;
            case 'min_max_price':
                if (dataType === 'cfPrice') {
                    val = <CFPrice id={id} data={data} title={title} handleClick={handleClick} allFilters={allFilters} setAllFilters={setAllFilters} showFilteredValues={showFilteredValues} />;
                } else {
                    val = <DWM id={id} data={data} title={title} handleClick={handleClick} allFilters={allFilters} setAllFilters={setAllFilters} showFilteredValues={showFilteredValues} />;
                }
                break;
            case 'min_max_year':
                val = <SidebarDropDown id={id} type={type} data={data} title={title} allFilters={allFilters} setAllFilters={setAllFilters} showFilteredValues={showFilteredValues} page={page} />;
                break;
            case 'min_max_kilometer':
                val = <SidebarDropDown id={id} type={type} data={data} title={title} allFilters={allFilters} setAllFilters={setAllFilters} showFilteredValues={showFilteredValues} page={page} />;
                // val=<KilometerInput id={id} data={data} title={title} allFilters={allFilters} setAllFilters={setAllFilters} />;
                break;
            case 'regional_space':
                // val = <Checkbox id={id} type={type} data={data} title={title} allFilters={allFilters} setAllFilters={setAllFilters} setSelected={setSelected} />;
                val = <RadioSelect id={id} type={type} data={data} title={title} allFilters={allFilters} setAllFilters={setAllFilters} setSelected={setSelected} />;
                break;
            case 'body':
                // val = <Checkbox id={id} type={type} data={data} title={title} allFilters={allFilters} setAllFilters={setAllFilters} setSelected={setSelected} />;
                val = <RadioSelect id={id} type={type} data={data} title={title} allFilters={allFilters} setAllFilters={setAllFilters} setSelected={setSelected} />;
                break;
            case 'paint':
                // val = <Checkbox id={id} type={type} data={data} title={title} allFilters={allFilters} setAllFilters={setAllFilters} setSelected={setSelected} />;
                val = <RadioSelect id={id} type={type} data={data} title={title} allFilters={allFilters} setAllFilters={setAllFilters} setSelected={setSelected} />;
                break;
            case 'colour':
                val = <Checkbox id={id} type={type} data={data} title={title} allFilters={allFilters} setAllFilters={setAllFilters} setSelected={setSelected} />;
                break;
            case 'gearbox':
                // val = <Checkbox id={id} type={type} data={data} title={title} allFilters={allFilters} setAllFilters={setAllFilters} setSelected={setSelected} />;
                val = <RadioSelect id={id} type={type} data={data} title={title} allFilters={allFilters} setAllFilters={setAllFilters} setSelected={setSelected} />;
                break;
            case 'fuel':
                val = <Checkbox id={id} type={type} data={data} title={title} allFilters={allFilters} setAllFilters={setAllFilters} setSelected={setSelected} />;
                break;
            case 'body_type':
                // val = <Checkbox id={id} type={type} data={data} title={title} allFilters={allFilters} setAllFilters={setAllFilters} setSelected={setSelected} />;
                val = <RadioSelect id={id} type={type} data={data} title={title} allFilters={allFilters} setAllFilters={setAllFilters} setSelected={setSelected} />;
                break;
            case 'min_max_engine':
                val = <Input id={id} type={type} data={data} title={title} allFilters={allFilters} setAllFilters={setAllFilters} showFilteredValues={showFilteredValues} setSelected={setSelected} />;
                break;
            case 'min_max_engine_power':
                val = <Input id={id} type={type} data={data} title={title} allFilters={allFilters} setAllFilters={setAllFilters} showFilteredValues={showFilteredValues} setSelected={setSelected} />;
                break;
            case 'cylinders':
                val = <Input id={id} type={type} data={data} title={title} allFilters={allFilters} setAllFilters={setAllFilters} showFilteredValues={showFilteredValues} setSelected={setSelected} />;
                break;
            case 'doors':
                val = <Input id={id} type={type} data={data} title={title} allFilters={allFilters} setAllFilters={setAllFilters} showFilteredValues={showFilteredValues} setSelected={setSelected} />;
                break;
            case 'seats':
                val = <Input id={id} type={type} data={data} title={title} allFilters={allFilters} setAllFilters={setAllFilters} showFilteredValues={showFilteredValues} setSelected={setSelected} />;
                break;
            // case 
            // case 'on select':
            //     val=<Select id={id} data={data} title={title} />;
            // break;
            // case 'input':
            //     val=<Input id={id} data={data} title={title} />;
            // break;
            // case 'sidebar':
            //     val=<SidebarDropDown id={id} data={data} title={title} />;
            // break;
            // case 'cfPrice':
            //     val=<CFPrice id={id} data={data} title={title}  handleClick={handleClick}/>;
            // break;
            // case 'checkbox':
            //     val=<Checkbox id={id} data={data} title={title} />;
            // break;
            // case 'D_W_M_Price':
            //     val=<DWM id={id} data={data} title={title}  handleClick={handleClick}/>;
            // break;
            default:
                val = null;
        }
        return val;
    }

    return (<>{fetchList()}</>);
}

