import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../Tools/Button";
import {
  fetchSingleCar,
  saveCarWithoutVinFormSubmit,
} from "../../../Reducer/api";
import Media from "../Media";
import { useState } from "react";
import { findUploads } from "../../../utils/constants";
export default function UploadImages({
  data,
  setPage,
  onClick,
  back,
  edit,
  next,
  page,
  setData,
}) {
  const { t, i18n } = useTranslation();
  const [uploadsInOrder, setUploadsInOrder] = useState([]);
  const [inputFile, setInputFile] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [InputFileError, setInputFileError] = useState();
  const [getImagesLoading, setGetImagesLoading] = useState(false);
  //upload document fucntion for adding new adverts only

  const getAdData = (id) => {
    setGetImagesLoading(true);
    fetchSingleCar({ id: id }).then((res) => {
      const dataTmp = res?.data?.[0];
      setData(dataTmp);

      const all_list = findUploads(dataTmp);
      setUploadsInOrder(all_list?.map((v, i) => ({ ...v, id: i + 1 })));
      setGetImagesLoading(false);
    });
  };

  useEffect(() => {
    if (!uploading && data?._id) {
      getAdData(data?._id);
    }
  }, []);

  const handlePostAd = async () => {
    if (!uploadsInOrder?.length) {
      setInputFileError(t("File is required"));
      return;
    }
    if (uploadsInOrder.length < 4) {
      setInputFileError(t("Please upload minimum four images"));
      return;
    }

    saveCarWithoutVinFormSubmit(
      { uploadsInOrder: { uploads: uploadsInOrder } },
      false,
      data?._id
    );

    // setSubmit(true);
    await setData({ ...data, uploadsInOrder: { uploads: uploadsInOrder } });
    setPage(next);
    setUploadsInOrder([]);
  };

  function additionalTask() {
    if (uploadsInOrder.length < 3) {
      setInputFileError(t("Please upload minimum four images"));
      saveCarWithoutVinFormSubmit(
        { isAdImageUploaded: "NO" },
        false,
        data?._id
      );
    } else {
      setInputFileError("");
      saveCarWithoutVinFormSubmit(
        { isAdImageUploaded: "YES" },
        false,
        data?._id
      );
    }
    // if (data?._id) {
    //   getAdData(data?._id);
    // }
  }
  console.log(data, "data in uploadImages");
  return (
    <>
      <h6 className="back">
        <a
          onClick={async () => {
            await setData({ ...data, uploadsInOrder: { uploads: uploadsInOrder } });
            setPage(back);
          }}
        >
          <img src="assets/images/backbt.png" /> {t("Go back")}
        </a>
      </h6>

      {/* <h3 class="Advert">{t("Your Advert")}</h3> */}
      <div class="se">
        <div class="row">
          <div className="col">
            <Media
              setInputFileError={setInputFileError}
              inputFile={inputFile}
              InputFileError={InputFileError}
              uploadsInOrder={uploadsInOrder}
              setUploadsInOrder={setUploadsInOrder}
              setInputFile={setInputFile}
              uploading={uploading}
              setUploading={setUploading}
              adId={data?._id}
              storedList={data?.uploads}
              type={6}
              callFunc={additionalTask}
              hasOrder
            />
          </div>
        </div>
      </div>
      <div class="text-center">
        <Button
          inlineStyle={{
            backgroundColor:
              uploadsInOrder?.length < 4 || uploading ? "gray" : null,
            cursor:
              uploadsInOrder?.length < 4 || uploading
                ? "not-allowed"
                : "default",
          }}
          style="save_continue disabled"
          onClick={() => {
            handlePostAd();
          }}
          type="submit"
          disabled={uploading || getImagesLoading}
          title={uploading ? t("Please wait...") : t("Save & Continue")}
        />
      </div>
    </>
  );
}
