import { paths } from "../utils/paths";

export const apiManager = async (method, path, data, addHeaders, contentType, newBase) => {
    let config = {
        method: method,
        headers: {
            'Content-Type': contentType ? contentType : 'application/json',
            authorization: process.env.REACT_APP_AUTH_TOKEN,
            accesstoken: localStorage.getItem('token')
        },
        body: contentType === 'multipart/form-data' ? data : JSON.stringify(data)
    }

    if (method === 'GET') {
        delete config.body;
    }
    if (addHeaders) {
        config = { ...config, headers: { ...config.headers, ...addHeaders } };
    }
    if(contentType === 'multipart/form-data') {
        delete config.headers['Content-Type'];
    }

    // console.log(config)
    
    return fetch(`${newBase || process.env.REACT_APP_BASE_URL}${path}`, config)
        .then(res => {
            if(res?.status === 403) {
                localStorage.removeItem('token')
                window.location.href = paths.login
            }
            return res.json()
        })
            // .catch(error => {
            //     return error
            // })

}
