import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { apiManager } from "../../../api/connectAPI";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { vehicleMy } from "../../../Reducer/myvehicleSlice";

export default function SoldModal({ handleCloseModal, modalData, refresh }) {
  const { t, i18n } = useTranslation();
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";
  const [buyers, setBuyers] = useState(null);
  const [selectedBuyer, setSelectedBuyer] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [type, setType] = useState("ako");

  const handleChangeRadio = (e) => {
    setType(e.target.value);
  };

  useEffect(() => {
    getBuyerList(modalData?._id);
  }, []);

  const getBuyerList = (adId) => {
    apiManager("GET", `chat/list?lang=${lang}&adId=${adId}`)
      .then((res) => {
        if (res.status) {
          setBuyers(res?.chats?.docs);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message);
      });
  };

  const markSold = () => {
    setLoading(true);
    let data = {
      adId: modalData?._id,
      soldWithinPlatform: type === "ako" ? true : false,
    };

    if (type === "ako") {
      data = { ...data, soldToUserId: selectedBuyer };
    }
    // refresh();
    // handleCloseModal();
    apiManager("PUT", `opp/ad/sold?lang=${lang}`, data)
      .then((res) => {
        if (res.status) {
          // toast.success(res.message);
          console.log(res);
          refresh();
          handleCloseModal();
        } else {
          toast.error(res.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message);
        setLoading(false);
      });
  };
  
  return (
    <div className="p-2">
      <form class="mb-2">
        <div class="d-flex justify-content-between p-2">
          <h5 class="modal-title" id={`exampleModalLabel${modalData?._id}`}>
            {t("Mark")} {t(modalData?.properties?.basicDetails?.make?.[lang])}{" "}
            {t(modalData?.properties?.basicDetails?.model?.[lang])}{" "}
            {t("as sold")}
          </h5>
          <a
            onClick={() => handleCloseModal()}
            type="button"
            style={{ backgroundColor: "transparent" }}
            class="stclose"
            // data-bs-dismiss="modal"
            // aria-label="Close"
          >
            <img src="../assets/images/close_img.png" alt="img" height={18} />{" "}
          </a>
        </div>
        <div class="modal-body">
          <div className="row d-flex justify-content-end">
            <div className="col-sm-6 my-2">
              <label
                htmlFor="ako"
                className="d-flex"
                style={{ cursor: "pointer" }}
              >
                <input
                  className={`m${lang === "en" ? "e" : "s"}-2`}
                  type="radio"
                  checked={type == "ako"}
                  id="ako"
                  name="sellRadio"
                  value={"ako"}
                  onChange={handleChangeRadio}
                />
                {t("Within Ako Sayara")}
              </label>
            </div>
            <div className="col-sm-6 my-2">
              <label
                htmlFor="out"
                className="d-flex"
                style={{ cursor: "pointer" }}
              >
                <input
                  className={`m${lang === "en" ? "e" : "s"}-2`}
                  type="radio"
                  checked={type == "out"}
                  id="out"
                  name="sellRadio"
                  value={"out"}
                  onChange={handleChangeRadio}
                />
                {t("Outside Ako Sayara")}
              </label>
            </div>
          </div>

          {type == "ako" ? (
            <>
              <label>{t("Select Buyer")}*</label>
              <select
                class="form-control"
                onChange={(e) => setSelectedBuyer(e.target.value)}
              >
                <option selected disabled hidden>
                  {t("Select")}
                </option>
                {buyers?.length != 0 ? (
                  buyers?.map((item, key) => {
                    return (
                      <option value={item?.buyerId}>{item?.buyerName}</option>
                    );
                  })
                ) : (
                  <option disabled>{t("No buyers")}</option>
                )}
              </select>
            </>
          ) : (
            <h5 className="my-4">
              {t("Mark as Sold") + " " + t("Outside Ako Sayara")}
            </h5>
          )}
        </div>
        <div class="float-end">
          <button
            type="button"
            class="btn btn-secondary ms-3 mt-4"
            // data-bs-dismiss="modal"
            onClick={() => handleCloseModal()}
          >
            {t("Cancel")}
          </button>
          <button
            type="button"
            disabled={loading}
            onClick={() => markSold()}
            class="btn btn-primary ms-3 mt-4"
          >
            {loading ? t("Please wait...") : t("Sold")}
          </button>
        </div>
      </form>
    </div>
  );
}
