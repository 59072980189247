import React from "react";
import RentalItem from "../../Tools/RentalItem";
import Pagination from "../../Tools/Pagination";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
export default function Car({
  data,
  pageCount,
  page,
  currentPage,
  handlePageClick,
  allFilters
}) {
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  return (
    <div
      class={`tab-pane fade ${page === "normal" ? "show active" : ""}`}
      id="Cars-tab-pane"
      role="tabpanel"
      aria-labelledby="Cars-tab"
      tabindex="0"
      style={{ minHeight: "120vh" }}
    >
      {data && data.length > 0 ? (
        data.map((item, index) => {
          return <RentalItem data={item} key={index} page={page} rentType={allFilters.rentType} />;
        })
      ) : (
        <h1
          style={{
            minHeight: "100vh",
            textAlign: "center",
            fontSize: "20px",
            marginTop: "25vh",
          }}
        >
          {"0 ".concat(t("Cars found"))}
        </h1>
      )}
      <nav aria-label="Page navigation example">
        <Pagination
          style={`pagination float-end mb-5 mt-4 ${lang !== 'en' && 'paginationButtonAr'}`}
          next={"Next"}
          forcePage={currentPage}
          previous={"Previous"}
          pageCount={pageCount}
          rangeDisplayed={3}
          handlePageClick={handlePageClick}
        />
      </nav>
    </div>
  );
}
