import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { brandSection } from "../../../data/content";
import Button from "../Button";
import { fetchBrands } from "../../../Reducer/brandsSlice";
import ImageBox from "../ImageBox";
import { useNavigate } from "react-router-dom";
import { getMake } from "../../../Reducer/api";
import { paths } from "../../../utils/paths";

export default function BrandSection({ brandTitle, page }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [mainClass, setMainClass] = useState("browse_brand");
  const brands = useSelector((state) => state.brands.brands);
  const loading = useSelector((state) => state.brands.loading);
  const error = useSelector((state) => state.brands.error);
  const [makes, setMakes] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchBrands());
    
    getMake({ isLive: true, limit: 200, ownerId: "", page: page }).then(
      (res) => {
        // res = res?.map((v) => ({ label: v, value: v }));
        let data = {
          name: t("Any"),
          value: "Any",
        };
        setMakes([data, ...res?.makes]);
      }
    );
  }, []);

  const isObjectEmpty = (objectName) => {
    return objectName && Object?.keys(objectName)?.length === 0;
  };
  let response =
    brands && Object?.keys(brands)?.length !== 0 ? brands : brandSection;
  let language = i18n.language;
  let lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";
  const topBrands = ["Toyota", "Honda", "Bmw", "Audi"];
  let topFour = makes ? makes?.filter((v) => topBrands.includes(v.name)) : [];
  topFour = [...new Set([...topFour, ...makes.slice(1, 5)])];

  const goToSearch = (item) => {
    if (page === "normal") {
      navigate(paths.buyCar, { state: { filters: { make: item?.name } } });
      console.log(item);
      return;
    }
    if (page === "rental") {
      navigate(paths.rentCar, { state: { filters: { make: item?.name } } });
      console.log(item);
      return;
    }
    navigate(paths.buyCar, { state: { filters: { make: item?.name } } });
  };

  return (
    <section class={mainClass} style={{ marginTop: "60px" }}>
      {loading == false && isObjectEmpty(brands) == false ? (
        <div class="container">
          <h2 class="hed text-center">{brandTitle}</h2>
          <div class="row">
            {topFour?.slice(0, 4)?.map((item, key) => {
              return (
                <ImageBox
                  onClick={() => goToSearch(item)}
                  singleImageStyle={{ cursor: "pointer" }}
                  key={key}
                  style={
                    key == 3
                      ? "col-4 col-sm-3 d-none d-sm-block"
                      : "col-4 col-sm-3"
                  }
                  path={item?.image}
                  value={item?.name ? t(item?.name) : " "}
                  // imageHeight={"100px"}
                  imageClasses={"topFourMob"}
                  titleStyles={{
                    marginTop: "-1px",
                  }}
                />
              );
            })}
          </div>
          <div class="row st">
            {makes?.slice(1)?.map((item, key) => {
              return (
                <ImageBox
                  onClick={() => goToSearch(item)}
                  singleImageStyle={{ cursor: "pointer" }}
                  key={key}
                  style={"col-3 col-xl-2"}
                  path={item?.image}
                  value={item?.name ? t(item?.name) : " "}
                  // imageHeight={"55px"}
                  imageClasses={"showMoreBrands"}
                />
              );
            })}
          </div>
          <div class="w-100 text-center mt-5">
            <Button
              style="show btn-brands"
              onClick={() => {
                setMainClass("browse_brand main");
              }}
              title={
                <>
                  {t("Show all brands")}
                  <br />
                  <i class="fa-solid fa-chevron-down"></i>
                </>
              }
            ></Button>
            <Button
              style="show ap btn-brands"
              onClick={() => {
                setMainClass("browse_brand");
              }}
              inlineStyle={{ display: "none" }}
              title={
                <>
                  <i class="fa-solid fa-chevron-up"></i>
                  <br />
                  {t("Show less")}
                </>
              }
            ></Button>
          </div>
        </div>
      ) : loading == true ? (
        <div className="d-flex justify-content-center">
          <div class="spinner-grow" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        ""
      )}
    </section>
  );
}
