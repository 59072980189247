import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import FormInput from "../../Tools/FormInput";
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { apiManager } from "../../../api/connectAPI";
import { fetchSingleCar } from "../../../Reducer/api";
import PhoneNumberInput from "../../Tools/PhoneNumberInput";
import CustomPhoneInput from "../../Tools/CustomPhoneInput";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "../../Tools/Button";
export default function EnquiryForm({ adId, adData, handleCloseEnquireModal }) {
  const { t, i18n } = useTranslation();
  const udata = useSelector((state) => state.auth);
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";

  const langCaptcha =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ckb";
  const [captchaCode, setCaptchaCode] = useState("");

  const [Enqurdata, setEnqurdata] = useState({
    number: "",
    first_name: "",
    last_name: "",
    email: "",
    comment: "",
    address: "",
  });

  const schema = Yup.object().shape({
    phoneNumber: Yup.string()
      .min(9, t("Phone number is not valid"))
      .max(16, t("Phone number is not valid")),
    first_name: Yup.string().required(t("First name is required")),
    last_name: Yup.string().required(t("Last Name is required")),
    comment: Yup.string().required(t("Message is required")),
  });

  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      phoneNumber: "",
    },
  });

  // const [phoneNumber, setPhoneNumber] = useState();
  // const [phoneNumberErrors, setPhoneNumberErrors] = useState();

  const onError = (errors) => {
    // if (!phoneNumber) setPhoneNumberErrors(t("phone number is required"));
    console.log(errors);
  };
  const [enquireLoading, setEnquireLoading] = useState(false);
  const onSubmitform = async (data) => {
    if (!submitting) {
      setEnquireLoading(true);

      const formData = {
        adId: adId,
        firstName: data.first_name,
        lastName: data.last_name,
        email: data.email,
        phoneNumber: data.phoneNumber,
        message: data.comment,
        token: captchaCode
      };
      console.log(formData);

      apiManager("POST", `general/enquiry?lang=${lang}`, formData)
        .then((res) => {
          if (res.status) {
            handleCloseEnquireModal();
          } else {
            toast.error(res.message);
          }
          setEnquireLoading(false);
        })
        .catch((err) => {
          console.error(err);
          toast.error(err.message);
          setEnquireLoading(false);
        });
    }
  };
  const submitting = false;

  const handleCaptureCaptcha = (code) => {
    console.log(code);
    setCaptchaCode(code);
  };

  return (
    <div className="login">
      {/* <h6 class="back">
        <a style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
          <img src="../assets/images/backbt.png" /> {t("Go back")}
        </a>
      </h6> */}
      <form onSubmit={handleSubmit(onSubmitform, onError)} class="">
        <h1 class="text-center">{t("Your Enquiry")}</h1>
        {/* <h5>{t("Contact Likeston about Ford Ecosport SUV")}</h5> */}
        {adData && (
          <h5>{`${t("EnquiryContact")} ${
            adData?.owner?.dealerShipName &&
            adData?.owner?.dealerShipName !== "N/A"
              ? adData?.owner?.dealerShipName
              : adData?.owner?.fullName
          } ${t("EnquiryAbout")} ${t(
            adData?.properties?.basicDetails?.make?.[lang]
          )} ${t(adData?.properties?.basicDetails?.model?.[lang])}`}</h5>
        )}
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group" style={{ position: "relative" }}>
              <label>{t("First Name")}</label>
              <FormInput
                style={"form-control"}
                intype={"text"}
                event={register}
                value={Enqurdata.first_name}
                name={"first_name"}
                placeholder={t("First Name")}
              />
              {errors.first_name && (
                <p className="helperText">{t(errors.first_name.message)}</p>
              )}
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group" style={{ position: "relative" }}>
              <label>{t("Last Name")}</label>
              <FormInput
                style={"form-control"}
                intype={"text"}
                event={register}
                value={Enqurdata.last_name}
                name={"last_name"}
                placeholder={t("Last Name")}
              />
              {errors.last_name && (
                <p className="helperText">{t(errors.last_name.message)}</p>
              )}
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-group" style={{ position: "relative" }}>
              <label>{t("Email (Optional)")}</label>
              <FormInput
                style={"form-control"}
                intype={"email"}
                event={register}
                value={Enqurdata.email}
                name={"email"}
                placeholder={t("Email (Optional)")}
              />
              {errors.email && (
                <p className="helperText">{t(errors.email.message)}</p>
              )}
            </div>
          </div>
          <div class="col-sm-12 mb-3">
            <div class="form-group" style={{ position: "relative" }}>
              <label>{t("Phone Number")}</label>

              <CustomPhoneInput
                event={register}
                name={"phoneNumber"}
                setValue={setValue}
                getValues={getValues}
              />
              {errors.phoneNumber ? (
                <p className="helperText">{t(errors.phoneNumber.message)}</p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-group" style={{ position: "relative" }}>
              <label>{t("Your Message")}</label>
              <FormInput
                style={"form-control"}
                intype={"textarea"}
                event={register}
                value={Enqurdata.comment}
                name={"comment"}
                placeholder={t("Your Message")}
              />
              {errors.comment && (
                <p className="helperText">{t(errors.comment.message)}</p>
              )}
            </div>
          </div>
          <div class="col-sm-12 d-flex justify-content-start mb-3">
            <ReCAPTCHA
              hl={langCaptcha}
              sitekey={process.env.REACT_APP_WEBSITE_KEY}
              onChange={handleCaptureCaptcha}
            />
          </div>
          <div class="col-sm-12 text-center">
            <Button
              disabled={enquireLoading || !captchaCode}
              style="themeButton"
              title={enquireLoading ? t("Please wait...") : t("Enquire Now")}
              type="submit"
            />
          </div>
        </div>
      </form>
    </div>
  );
}
