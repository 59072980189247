import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import i18n from "../../../../../i18n";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { paths } from "../../../../../utils/paths";

export default function KycDocuments({ data }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const Basicetails = data.Basicetails;
  var length = Object.keys(data).length;
  const handleClick = () => {
    navigate(paths.dealershipDocument, { state: { editing: true, userId: data?._id} });
  };
  const [modal, setModal] = useState(false);
  const [doc, setDoc] = useState("");
  const openModal = (url) => {
    setDoc(url);
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
  };
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";
  const getKycStatus = (status) => {
    let txt = status?.en?.replace(".", "");
    let color = "";
    if (txt.includes("verified")) {
      color = "success";
    } else if (txt.includes("rejected")) {
      color = "danger";
    } else {
      color = "warning";
      txt = "Pending";
    }
    return (
      <span class={`badge bg-${color}`} style={{ fontSize: "12px" }}>
        {t(txt)}
      </span>
    );
  };
  return (
    <div style={{ position: "relative" }}>
      {length > 0 ? (
        <>
          <h3 class="securityh3 mb-4 mb-lg-0">
            {t("Dealership document")}
            {data?.additionalInfo?.dealerKycDocuments && (
              <a class="edt" onClick={handleClick}>
                <img src="../assets/images/pencil.png" alt="pencil" />{" "}
                <span className="d-none d-sm-block">{t("Edit document")}</span>
              </a>
            )}
          </h3>
          <div class="row">
            {data?.additionalInfo?.dealerKycDocuments ? (
              <div
                class="col-sm-12 d-flex justify-content-start thinScroll"
                style={{ overflowX: "scroll", overflowY: "unset" }}
              >
                {data?.additionalInfo?.dealerKycDocuments?.map((item, i) => {
                  return (
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={() => openModal(item)}
                      className="border p-1 m-1 rounded-2"
                      key={i}
                      src={item}
                      height={150}
                      alt=""
                    />
                  );
                })}
                <h5
                  className="mb-4 movePending"
                  style={{ position: "absolute", top: "62px" }}
                >
                  {getKycStatus(data?.kyc)}
                </h5>
              </div>
            ) : (
              <div className="d-flex justify-content-center mb-4">
                <div className="row p-3">
                  <div className="col-lg-12 text-center">
                    {t("Upload Dealership Document")}
                  </div>
                  <div className="col-lg-12">
                    <a
                      class="d-flex justify-content-center"
                      onClick={handleClick}
                    >
                      <img
                        src="../assets/images/pencil-alt.webp"
                        height={25}
                        alt="pencil"
                      />{" "}
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        ""
      )}
      <Modal isOpen={modal} toggle={closeModal} centered fullscreen>
        <ModalHeader toggle={closeModal}></ModalHeader>
        <ModalBody className="d-flex justify-content-center align-items-center">
          <img
            src={doc || "assets/images/notFound.png"}
            style={{ objectFit: "contain" }}
            className="w-100 h-100"
            alt=""
          />
        </ModalBody>
      </Modal>
    </div>
  );
}
