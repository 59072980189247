import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { NavLink, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { fetchdescribe } from "../../../Reducer/ConteactUsSilce";
import SelectForm from "../../Tools/SelectForm";
import FormInput from "../../Tools/FormInput";
import { accountClosureReasons, errorSms, title } from "../../../data/content";
import { contactFormSubmit } from "../../../Reducer/api";
import PhoneNumberInput from "../../Tools/PhoneNumberInput";
import { clearToken, clearUser } from "../../../Reducer/authSlice";
import CustomPhoneInput from "../../Tools/CustomPhoneInput";
import { ldSchemas } from "../../../utils/json-schemas";
import JsonLDSchemaHandler from "../../Tools/JSONLDSchemaUtil";

export default function CloseAccount() {
  const submit = false;
  const dispatch = useDispatch();
  const dataContent = useSelector((state) => state.contactus.detailarr);
  useEffect(() => {
    dispatch(fetchdescribe());
  }, [dispatch]);
  const detail = useSelector((state) => state.contactus.auth);
  const error = useSelector((state) => state.contactus.error);
  const { t, i18n } = useTranslation();
  const userType = localStorage.getItem("userType");
  const userData = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const schema = Yup.object().shape({
    fullName: Yup.string()
      .min(3, t("Full name must be at least 3 characters"))
      .max(30, t("Full name must be at most 30 characters"))
      .required(t("Full name is a required field")),
    email: Yup.string().email(t('Please Enter Valid email address')),
    phoneNumber: Yup.string()
      .min(9, t("Phone number is not valid"))
      .max(16, t("Phone number is not valid")),
    reason: Yup.string()
      .test("notChoose", t("Select a reason"), (v) => v !== t("Select"))
      .required(t("Subject is required")),
    about: Yup.string().required(t("About is required")),
  });

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      phoneNumber: ''
    }
  });
  const onError = (errors) => {
    // if (!phoneNumber) setPhoneNumberErrors("Phone number is required");
    console.log(errors);
  };
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const onSubmitform = async (data) => {
    // if (!phoneNumber) setPhoneNumberErrors("Phone number is required");

    // if (submit == false) {
    //   let formData = new FormData();
    //   formData.append("reason", data.fullname);
    //   formData.append("tellAbout", data.number);
    //   toast.success("Your Request Send Successfully");
    // }

    const formData = {
      name: data?.fullName,
      email: data?.email,
      phoneNumber: data?.phoneNumber,
      about: data.about,
      message: data.reason,
      role: userType === "dealer" ? "I am a dealer" : "I am an individual",
    };
    contactFormSubmit({
      formData: formData,
      setLoading: setLoading,
      reset: resetFun,
      setSuccessMessage: setSuccessMessage,
    }).then((res) => {
      console.log(res);
      if (res.status) {
        handleLogout();
        // setSuccessMessage(res.message)
        // setTimeout(() => {
        //   handleLogout()
        // }, 5000)
      }
    });
  };
  const handleLogout = () => {
    dispatch(clearUser());
    dispatch(clearToken());
    navigate("/login");
  };

  if (error && error.status) {
    switch (error.status) {
      case 403:
        toast.error(error.data.message);
        break;
      default:
        toast.error(t(errorSms.first));
        break;
    }
  }
  const resetFun = () => {
    reset();
    // setPhoneNumber("");
  };

  // const [phoneNumber, setPhoneNumber] = useState("");
  // const [phoneNumberErrors, setPhoneNumberErrors] = useState("");
  return (
    <div className=" container min-vh-100">
      <div class="close_amount mt-5">
        <h3>{t("Closure Of Account")}</h3>

        <form onSubmit={handleSubmit(onSubmitform, onError)}>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label>{t("Your Name")}</label>
                <FormInput
                  style={"form-control"}
                  intype={"text"}
                  event={register}
                  name={"fullName"}
                  placeholder={t("Your Name")}
                />
                {errors.fullName && (
                  <p className="error">{t(errors.fullName.message)}</p>
                )}
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label>{t("Phone Number")}</label>
                {/* <PhoneNumberInput
                  phoneNumber={phoneNumber}
                  setPhoneNumber={setPhoneNumber}
                  phoneNumberErrors={phoneNumberErrors}
                  setPhoneNumberErrors={setPhoneNumberErrors}
                  dontShowErrors
                />
                {phoneNumberErrors && (
                  <p className="error" style={{ marginTop: "-17px" }}>
                    {t(phoneNumberErrors)}
                  </p>
                )} */}
                <CustomPhoneInput
                  event={register}
                  name={"phoneNumber"}
                  setValue={setValue}
                  getValues={getValues}
                />
                {errors.phoneNumber ? (
                  <p>{t(errors.phoneNumber.message)}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label>{t("Email (Optional)")}</label>
                <FormInput
                  style={"form-control"}
                  intype={"text"}
                  event={register}
                  name={"email"}
                  placeholder={t("Email")}
                />
                {errors.email && (
                  <p className="error">{t(errors.email.message)}</p>
                )}
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label>{t("Select Reason for Clossing Your Account")}</label>
                <SelectForm
                  style={"form-control"}
                  event={register}
                  name={"reason"}
                  values={accountClosureReasons ? accountClosureReasons : []}
                  // placeholder={'Select a reason'}
                />
                {errors.reason && <p>{t(errors.reason.message)}</p>}
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label>{t("Tell us")}</label>
                <FormInput
                  style={"form-control"}
                  intype={"text"}
                  event={register}
                  name={"about"}
                  placeholder={t("Type your message")}
                />
                {errors.about && (
                  <p className="error">{t(errors.about.message)}</p>
                )}
              </div>
            </div>
          </div>

          <div class="col-xl-6 text-xl-end w-100">
            <a className="Cancel" onClick={() => navigate(-1)}>
              {t("Cancel")}
            </a>
            <button type="button" onClick={handleSubmit(onSubmitform, onError)}>
              {loading ? t("Please wait...") : t("Close my Account")}
            </button>
          </div>
          {successMessage && (
            <div className="col-lg-12">
              <ul
                class="livetst w-100 mt-4 rounded p-2"
                style={{
                  top: "unset",
                  marginBottom: "15px",
                  border: "1px solid #ebebeb",
                  backgroundColor: "#28c5281f",
                }}
              >
                <span className="mx-2 fs-5">{successMessage}</span>
              </ul>
            </div>
          )}
        </form>
      </div>


      <JsonLDSchemaHandler data={ldSchemas?.closeAccount} />
    </div>
  );
}
