import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import RemoveModal from "./RemoveModal";
import SoldModal from "./SoldModal.";
import { Modal } from "reactstrap";
import MyAdCard from "./my-ad-card";
import InfiniteScroll from "react-infinite-scroller";
export default function MyvehicleTool({ data, refresh, goNext }) {
  const { i18n } = useTranslation();
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";

  const [modal, setModal] = useState(false);
  const [modaltype, setModalType] = useState("");
  const [modalData, setModalData] = useState();
  const openModal = (type, data) => {
    setModalType(type);
    setModalData(data);
    setModal(true);
  };
  const handleCloseModal = () => {
    setModal(false);
  };

  console.log(data?.data?.totalAds);

  const handleTableScroll = (event) => {
    console.log(event.target.scrollTop, event.target, "scroll, atts");
    // if (event.target.scrollTop > 50) {
    //   setShowStickyHeader(true);
    // } else if (event.target.scrollTop < 60) {
    //   setShowStickyHeader(false);
    // }
  };

  return (
    <>

      <InfiniteScroll
        dataLength={data?.data?.totalAds}
        // className="row px-0"
        pageStart={0}
        loadMore={goNext}
        hasMore={data?.data?.hasNextPage}
        loader={
          <div className="loader d-flex justify-content-center my-3" key={0}>
            <div class="spinner-grow" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        }
      >
        <div className="row">

        {data?.data?.ads &&
          data?.data?.ads.map((item, key) => {
            return (
              <MyAdCard
                key={key}
                item={item}
                lang={lang}
                openModal={openModal}
              />
            );
          })}
        </div>
      </InfiniteScroll>
      
      <Modal isOpen={modal} toggle={handleCloseModal} centered>
        {modaltype === "sold" && (
          <SoldModal
            handleCloseModal={handleCloseModal}
            modalData={modalData}
            refresh={refresh}
          ></SoldModal>
        )}
        {modaltype === "remove" && (
          <RemoveModal
            refresh={refresh}
            handleCloseModal={handleCloseModal}
            modalData={modalData}
          />
        )}
      </Modal>
    </>
  );
}
