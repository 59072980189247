import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { title } from "../../../../../data/content";
import { clearStatus, myprofilefetch } from "../../../../../Reducer/myprofileSlice";
import EditWorkingHour from "./EditWorkingHour";
import Profile from "./Profile";
import Security from "./Security";
import WorkingHour from "./WorkingHour";
import KycDocuments from "./DealershipDocuments";
import UploadCover from "./UploadCover";

export default function MyProfile({ page }) {
  const data = useSelector((state) => state.myprofile.profilearr);
  const success = useSelector((state) => state.myprofile.success);
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const userType = localStorage.getItem("userType");

  useEffect(() => {
    dispatch(myprofilefetch({setLaoding: setLoading}));
  }, [dispatch]);

  useEffect(() => {
    console.log(success)
    if(success) {
      setLoading(false)
      clearStatus()
    }
  }, [success])


  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "50vh" }}
      >
        <div class="spinner-grow" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div class="my_profile">
      <Profile data={data ? data : []} page={userType} />
      <Security data={data ? data : []} />
      {userType == "dealer" ? <KycDocuments data={data ? data : []} /> : ""}
      {userType == "dealer" ? <WorkingHour data={data ? data : []} /> : ""}
      {userType == "dealer" ? <UploadCover data={data ? data : []} /> : ""}
    </div>
  );
}
