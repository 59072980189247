import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { likeDislikePost } from "../../../Reducer/api";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchSaved } from "../../../Reducer/savedSlice";
import { Icon } from "@iconify/react";
import Crousel from "../Crousel";
import SearchCrousel from "../SearchCrousel";
import { t } from "i18next";
import {
  beginWithImage,
  findUploads,
  formatPrice,
  isImageOrVideo,
  makeTagText,
} from "../../../utils/constants";
import { paths } from "../../../utils/paths";

const OwlItemSaved = ({
  responce,
  page,
  handleClick,
  onChange,
  selectedList,
  fill,
  unfill,
  iconText,
  checkBoxText,
  listOfChecked,
  setlistOfChecked,
}) => {
  const token = useSelector((state) => state.auth.token);
  const language = useSelector((state) => state.auth.language);
  const [responces, setResponces] = useState(responce);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const saved = useSelector((state) => state.saved.saved);
  const [loading, setLoading] = useState(false);
  const [beingSaved, setBeingSavedId] = useState("");

  const update = async (id, updatedData) => {
    return await responces.map((item) =>
      item.id === id ? { ...item, ...updatedData } : item
    );
  };

  const click = async (id, liked) => {
    if (token) {
      try {
        let data = { postId: id, like: liked };
        const val = { liked: liked };
        const result = await update(id, val);
        setResponces(result);
        let respo = await likeDislikePost(data);
        if (respo.message) {
          toast.success(respo.message);
        }
      } catch (e) {
        toast.error(e.responce ? e.responce.message : e.message);
      }
    }
    //handleClick(true);
  };

  const selectBox = async (items, e) => {
    if (e) {
      // if (listOfChecked?.length >= 4) {
      //   toast.warn(t("Currently only 4 cars can be compared at a time"));
      // } else
      if (!listOfChecked?.includes(items._id)) {
        setlistOfChecked([...listOfChecked, items._id]);
      }
    } else {
      const newArr = listOfChecked?.filter((v) => v !== items._id);
      setlistOfChecked(newArr);
    }

    let data = selectedList;
    const index = data.findIndex((obj) => obj._id === items._id);
    if (index !== -1) {
      onChange([
        ...data.slice(0, index),
        ...data.slice(index + 1, data.length),
      ]);
    } else {
      onChange([...data, items]);
    }
  };

  const deleteSavedCar = async (adId) => {
    if (!localStorage.getItem("token")) {
      navigate("/login", {
        state: { backPage: location.pathname + location?.search },
      });
      return;
    }
    setBeingSavedId(adId);
    setLoading(true);
    let res = await likeDislikePost(adId, "remove", setLoading);
    if (res) {
      dispatch(fetchSaved());
      setResponces([]);
    }
  };
  // console.log(selectedList)
  const gotoCar = (item) => {
    const type = item?.adTypeName?.en;
    const id = item?._id;
    navigate(`/${type === "Sell" ? "SingleCar" : "SingleRental"}?adId=${id}`, {
      state: { back: paths.saved },
    });
  };
  return (
    <>
      {saved?.ads?.map((item, key) => {
        const tagText = makeTagText(item, lang);
        let firstImage =
          findUploads(item)?.[0]?.thumbnail || item?.uploads?.[0]?.s3URL;
        const type = isImageOrVideo(firstImage);
        return (
          <div class="item">
            <div class="set">
              {item?.owner?.userType !== "dealer" && (
                <p className="owl-item-badge">
                  {" "}
                  <span
                    style={{
                      borderRadius:
                        lang !== "en" ? "0px 0px 0px 5px" : "0px 0px 5px 0px",
                      fontSize: "11px",
                      backgroundColor: "#334660",
                    }}
                    class=" text-light float-start px-4"
                  >
                    {t("Private ad")}
                  </span>
                </p>
              )}
              {page == "saved" && (
                <label class="checkcontainer" style={{ zIndex: "10" }}>
                  {checkBoxText}
                  <input
                    type="checkbox"
                    onChange={(e) => selectBox(item, e.target.checked)}
                    // disabled={selectedList.length >= 4}
                    name="quality"
                    checked={listOfChecked?.includes(item._id)}
                    class="quality"
                    value="I1"
                  />
                  <span class="radiobtn"></span>
                </label>
              )}
              <div
                style={{ cursor: "pointer", borderRadius: "5px" }}
                onClick={() => gotoCar(item)}
              >
                {type === "video" ? (
                  <video
                    controls={false}
                    className="rounded-2 small-icon"
                    height={290}
                    style={{
                      objectFit: "cover",
                      marginBottom: "-7px",
                      width: "100%",
                    }}
                  >
                    <source src={firstImage || "assets/images/notFound.png"} />
                  </video>
                ) : (
                  <img
                    src={firstImage || "assets/images/notFound.png"}
                    alt="blog1"
                    height={290}
                    className={`small-icon`}
                    style={{ objectFit: "cover" }}
                  />
                )}
                {/* <SearchCrousel images={images} id={item?._id}/> */}
              </div>
              <div style={{ cursor: "pointer" }} onClick={() => gotoCar(item)}>
                <h3>
                  <h3
                    className="smallScreenFont"
                    style={{ marginBlock: "0px" }}
                  >
                    {t(item?.properties?.basicDetails?.make?.[lang])}{" "}
                    {t(item?.properties?.basicDetails?.model?.[lang])}
                  </h3>
                  <span className="">
                    <img
                      src={"../assets/images/red_fill.png"}
                      id={`unfill${item.id + page}`}
                      align="icon"
                    />
                    {iconText}
                  </span>
                </h3>
                <p
                  style={{
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    height: "45px",
                  }}
                >
                  {tagText}
                </p>
                <br />
                {/* <span class="title match" data-mh="title">
                  {item?.entity?.properties?.specifications?.fuelType?.[lang]}
                </span> */}
              </div>
              <div
                className="d-flex justify-content-between cardSmallSaved"
                style={{ paddingBottom: "5px" }}
              >
                <div
                  className="fw-semibold smallScreenFont"
                  style={{
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    height: "45px",
                  }}
                >
                  {item?.adTypeName?.["en"] === "Sell"
                    ? formatPrice(lang, item?.askPrice, item?.dollorAskPrice)
                    : formatPrice(lang, item?.askPrice, item?.dollorAskPrice).concat("/Day")}
                </div>
                <div>
                  {loading && beingSaved === item?._id ? (
                    <button
                      class="btn btn-sm btn-light"
                      style={{
                        backgroundColor: "transparent",
                        border: "unset",
                        height: 20,
                      }}
                      type="button"
                      disabled
                    >
                      <span
                        class="spinner-border spinner-border-sm"
                        aria-hidden="true"
                      ></span>
                      <span class="visually-hidden" role="status">
                        Loading...
                      </span>
                    </button>
                  ) : (
                    <Icon
                      icon="ic:baseline-delete"
                      width="27"
                      color="red"
                      style={{ cursor: "pointer" }}
                      onClick={() => !loading && deleteSavedCar(item._id)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
export default OwlItemSaved;
