import React from "react";
import { Modal, ModalBody } from "reactstrap";

export default function ViewImageModal({ modal, handleClose, modalData }) {
  return (
    <div>
      <Modal isOpen={modal} toggle={handleClose} centered style={{ maxWidth: "" }} >
        <ModalBody className="position-relative overflow-hidden" >
          <span
            role="button"
            onClick={() => handleClose()}
            className="position-absolute bg-light rounded-circle"
            style={{
              top: "23px",
              right: "25px",
              boxShadow: "0px 0px 6px 0px grey",
            }}
          >
            <img
              src="/assets/images/close_img.png"
              height={25}
              width={25}
              className="object-contain rounded-2"
            />
          </span>
          <img
            class={`w-100 bg-light p-${
              modalData?.image && modalData?.image != "N/A" ? "" : "4"
            }`}
            src={
              modalData?.image && modalData?.image != "N/A"
                ? modalData?.image
                : "/assets/images/ap2.png"
            }
            style={{ objectFit: "contain", maxHeight: '700px' }}
            align="img"
          />
        </ModalBody>
      </Modal>
    </div>
  );
}
