export const getFieldNames = (step) => {
  switch (step) {
    case 1:
      return ["make", "model", "makeYear", "regional", "condition", "colour"];
    case 2:
      return [
        "paint",
        "kilometers",
        "transmission",
        "fuel",
        "body",
        "doors",
        "seats",
      ];
    case 3:
      return ["brandNew"];
    case 4:
      return [
        "city",
        "asking_price",
        "daily_price",
        "weekly_price",
        "monthly_price",
      ];
  }
};
